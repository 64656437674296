import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { DesktopContext } from "../../../App";

import {
  calculateMonthlyRepayments,
  calculateTotalRepayment,
  calculateInterestOnlyMonthlyRepayments,
  calculateInterestOnlyTotalRepayment,
  calculateTotalInterest,
  calculateYearlyMortgageDetails,
} from "./calculations";
import SummaryComponent from "./SummaryComponent";
import ChartSingle from "./ChartSingle";
import RepaymentTable from "./RepaymentTable";

export default function Results(settedData) {
  const data = settedData;
  const [showTable, setShowTable] = useState(false);
  const desktop = useContext(DesktopContext);
  //console.log(data.settedData.loanTermText);

  const monthlyRepayments =
    data.settedData.mortgageType === "repayment"
      ? calculateMonthlyRepayments(
          data.settedData.mortgageAmount,
          data.settedData.interestRate,
          data.settedData.loanTermText
        )
      : calculateInterestOnlyMonthlyRepayments(
          data.settedData.mortgageAmount,
          data.settedData.interestRate
        );

  const totalRepayment =
    data.settedData.mortgageType === "repayment"
      ? calculateTotalRepayment(monthlyRepayments, data.settedData.loanTermText)
      : calculateInterestOnlyTotalRepayment(
          data.settedData.mortgageAmount,
          monthlyRepayments,
          data.settedData.loanTermText
        );

  const totalInterest = calculateTotalInterest(
    totalRepayment,
    data.settedData.mortgageAmount
  );

  const totalCost = totalRepayment + totalInterest;

  const mortgageData = calculateYearlyMortgageDetails(
    data.settedData.mortgageAmount,
    data.settedData.loanTermText,
    monthlyRepayments,
    data.settedData.interestRate
  );

  const chartData = mortgageData.map((entry) => ({
    year: entry.year,
    remainingLoanAmount: Math.round(entry.remainingLoanAmount),
  }));

  //console.log(chartData);

  const maxYValue =
    Math.ceil(
      Math.max(...chartData.map((entry) => entry.remainingLoanAmount)) / 25000
    ) * 25000;

  return (
    <>
      <Box sx={{ mt: 7, mb: 4 }}>
        <SummaryComponent
          summaryHeading={"Mortgage Summary"}
          monthlyRepayments={monthlyRepayments}
          loanTermText={data.settedData.loanTermText}
          mortgageAmount={data.settedData.mortgageAmount}
          totalRepayment={totalRepayment}
          totalInterest={totalInterest}
          totalCost={totalCost}
        />
      </Box>

      {showTable ? (
    <RepaymentTable mortgageData={mortgageData} />
  ) : (
    <ChartSingle chartData={chartData} maxYValue={maxYValue} />
  )}

<Box 
  display="flex"
  justifyContent="center"
  alignItems="center"
>
  <Button
    variant="outlined"
    onClick={() => setShowTable(!showTable)}
    sx={{
      width: desktop ? "360px" : "100%",
      mt: showTable ? 4 : -4,
      mb: 2,
    }}
  >
    {showTable ? "Show chart" : "Show repayment table"}
  </Button>
</Box>



      
    </>
  );
}

Results.propTypes = {
  settedData: PropTypes.object.isRequired,
};
