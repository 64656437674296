import React, { useContext } from "react";
import PropTypes from "prop-types";
import { formatNumber } from "../../../utils/miscFunctions";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";

import { DesktopContext } from "../../../App";

export default function SummaryComponent({
  summaryHeading,
  monthlyRepayments,
  loanTermText,
  mortgageAmount,
  totalRepayment,
  totalInterest,
  summaryBackgroundColor,
}) {
  const desktop = useContext(DesktopContext);

  let summaryHeadingSize = "1.5rem";
  let summaryHorizPadding = 2;
  let summaryLabelSize = "1.2rem";
  let summaryMonthlyRepaymentSize = "1.7rem";
  let summaryTotalRepaymentSize = "1.5rem";
  if (desktop) {
    summaryHeadingSize = "1.8rem";
    summaryHorizPadding = 0;
    summaryLabelSize = "1.5rem";
    summaryMonthlyRepaymentSize = "2.0rem";
    summaryTotalRepaymentSize = "1.7rem";
  }

  return (
    <>
      <Card
        sx={{
          boxShadow: 0,
          backgroundColor: summaryBackgroundColor,
          color: "#FFF",
          pt: 4,
          pb: 4,
          pl: summaryHorizPadding,
          pr: summaryHorizPadding,
          borderRadius: "4px",
        }}
      >
        <Grid container spacing={0} sx={{ mb: 2.5 }}>
          <Grid item xs={12}>
            {summaryHeading && (
              <Box display="flex" justifyContent="center">
                <Typography component="span"
                  sx={{ fontWeight: "500 !important", fontSize: summaryHeadingSize, mt: -1, mb: 3 }}
                >
                  {summaryHeading}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={0} sm={2}></Grid>
          <Grid item xs={7} sm={5} sx={{}}>
            <Typography component="span" sx={{ fontWeight: 500, fontSize: summaryLabelSize }}>
              Monthly repayment
            </Typography>
            <Typography component="span" sx={{ fontSize: "0.9rem" }}>
              <br/>Assuming your interest rate stays the same
            </Typography>
          </Grid>
          <Grid item xs={5} sm={3}>
            <Box display="flex" justifyContent="flex-end">
              <Typography
              component="span"
                sx={{
                  fontWeight: 500,
                  fontSize: summaryMonthlyRepaymentSize,
                  mt: 0.2,
                }}
              >
                {formatNumber(monthlyRepayments)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={0} sm={2}></Grid>
          <Grid item xs={0} sm={2}></Grid>
          <Grid item xs={7} sm={5} sx={{ mt: 4 }}>
            <Typography component="span" sx={{ fontWeight: 500, fontSize: summaryLabelSize }}>
              Total over {loanTermText} years
            </Typography>
            <Typography component="span" sx={{ fontSize: "0.9rem" }}>
              <br/>Mortgage debt {formatNumber(mortgageAmount)} + total interest{" "}
              {formatNumber(totalInterest)}
            </Typography>
          </Grid>
          <Grid item xs={5} sm={3} sx={{ mt: 4 }}>
            <Box display="flex" justifyContent="flex-end">
              <Typography component="span"
                sx={{
                  fontWeight: 500,
                  fontSize: summaryTotalRepaymentSize,
                  mt: 0.2,
                }}
              >
                {formatNumber(totalRepayment)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={0} sm={2}></Grid>
        </Grid>
      </Card>
    </>
  );
}

SummaryComponent.defaultProps = {
  summaryBackgroundColor: "#19899D", // Replace with your desired default color
};

SummaryComponent.propTypes = {
  summaryHeading: PropTypes.string,
  monthlyRepayments: PropTypes.number.isRequired,
  loanTermText: PropTypes.string.isRequired,
  mortgageAmount: PropTypes.string.isRequired,
  totalRepayment: PropTypes.number.isRequired,
  totalInterest: PropTypes.number.isRequired,
  summaryBackgroundColor: PropTypes.string,
};
