import React, { useContext } from "react";
import PropTypes from "prop-types";
import { DesktopContext } from "../../../App";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import FormDivider from "../../../components/divider/FormDivider";


import { formatNumber } from "../../../utils/miscFunctions";
//import FormDivider from "../../../formElements/FormDivider";
//import Grid from "@mui/material/Grid";
//import Box from "@mui/material/Box";
//import Typography from "@mui/material/Typography";



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#4F145B",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#FBF5F9",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  
export default function RepaymentTable({mortgageData}) {
  const desktop = useContext(DesktopContext);


  return (

<>
<FormDivider dividerText={"Mortgage Repayment Details"} />
<Box display="flex" justifyContent="center" sx={{pt:desktop ? 2 : 0}}>
<TableContainer component={Paper} sx={{mt:3.5, width: desktop ? "72%" : "100%"}}>
  <Table aria-label="customized table">
    <TableHead>
      <TableRow>
        <StyledTableCell>Year</StyledTableCell>
        <StyledTableCell align="right">Interest Paid</StyledTableCell>
        <StyledTableCell align="right">Capital Paid</StyledTableCell>
        <StyledTableCell align="right">Mortgage Balance</StyledTableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {mortgageData.map((entry, index) => (
        <StyledTableRow key={index}>
          <StyledTableCell component="th" scope="row">
            {entry.year}
          </StyledTableCell>
          <StyledTableCell align="right">
            {formatNumber(entry.annualInterestPaid)}
          </StyledTableCell>
          <StyledTableCell align="right">
            {formatNumber(entry.annualCapitalPaid)}
          </StyledTableCell>
          <StyledTableCell align="right">
            {formatNumber(entry.remainingLoanAmount)}
          </StyledTableCell>
        </StyledTableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>
</Box>
</>

  );
}
RepaymentTable.propTypes = {
  mortgageData: PropTypes.array.isRequired,
};