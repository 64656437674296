import React, { useContext } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { DesktopContext } from "../../../App";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import FormDivider from "../../../components/divider/FormDivider";

//eslint-disable-next-line
const CustomTooltip = ({ active, payload, label }) => {
  //eslint-disable-next-line
  if (active && payload && payload.length) {
    //eslint-disable-next-line
    const value = payload[0].value.toLocaleString("en-GB", {
      style: "currency",
      currency: "GBP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return (
      <div
        style={{
          backgroundColor: "#FFF",
          borderColor: "#D80080",
          border: "1px solid",
          padding: "5px",
        }}
      >
        <p style={{ color: "#3e3e3e" }}>{`Year: ${label}`}</p>
        <p style={{ color: "#3e3e3e" }}>{value}</p>
      </div>
    );
  }

  return null;
};

const ChartSingle = ({chartData, maxYValue}) => {

  const desktop = useContext(DesktopContext);

  return (
    <>
      <FormDivider dividerText={"Mortgage Debt Over Time"} />
      <Box display="flex" justifyContent="center" sx={{pt:desktop ? 2 : 0}}>

      <ResponsiveContainer width={desktop ? "100%" : "100%"} height={desktop ? 400 : 350}>
        <LineChart
          data={chartData}
          margin={{ top: 30, right: 0, left: -29, bottom: 50 }}
        >
          <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
          <XAxis
            dataKey="year"
            type="number"
            ticks={chartData.map((entry) => entry.year)}
            domain={["dataMin", "dataMax"]}
            tick={{ fontSize: 10, fill: "#3e3e3e" }}
          ></XAxis>
          <YAxis
            interval={0}
            ticks={Array.from(
              { length: maxYValue / 50000 + 1 },
              (_, i) => i * 50000
            )}
            domain={[0, maxYValue]}
            tick={{ fontSize: 10, fill: "#3e3e3e" }}
            tickFormatter={(tick) => `${tick / 1000}K`}
          ></YAxis>
          <Tooltip content={<CustomTooltip />} />
          <Line
            type="monotone"
            dataKey="remainingLoanAmount"
            name="Mortgage Debt Over Time"
            stroke="#D80080"
            strokeWidth={3}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
      </Box>
    </>
  );
};

ChartSingle.propTypes = {
  chartData: PropTypes.array.isRequired,
  maxYValue: PropTypes.number.isRequired,
};

export default ChartSingle;
