// Calculate the fixed monthly repayments for a mortgage
export function calculateMonthlyRepayments(mortgageAmount, interestRate, loanTerm) {
  const monthlyInterestRate = interestRate / 1200;
  const numberOfPayments = loanTerm * 12;
  const monthlyRepayments = mortgageAmount * (monthlyInterestRate) * Math.pow((1 + monthlyInterestRate), numberOfPayments) / (Math.pow((1 + monthlyInterestRate), numberOfPayments) - 1);
  return monthlyRepayments;
}

// Calculate the fixed monthly repayments for an interest-only mortgage
export function calculateInterestOnlyMonthlyRepayments(mortgageAmount, interestRate) {
  const monthlyInterestRate = interestRate / 1200;
  const interestOnlyMonthlyRepayments = mortgageAmount * monthlyInterestRate;
  return interestOnlyMonthlyRepayments;
}

// Calculate the total repayment amount for interest only mortgage
export function calculateInterestOnlyTotalRepayment(mortgageAmount, interestOnlyMonthlyRepayments, loanTerm) {
  const totalRepayment = (Number(interestOnlyMonthlyRepayments) * Number(loanTerm) * 12) + Number(mortgageAmount);
  return totalRepayment;
}

// Calculate the total repayment amount for the mortgage
export function calculateTotalRepayment(monthlyRepayments, loanTerm) {
  const totalRepayment = monthlyRepayments * loanTerm * 12;
  return totalRepayment;
}

// Calculate the total interest paid on the mortgage
export function calculateTotalInterest(totalRepayment, mortgageAmount) {
  const totalInterest = totalRepayment - mortgageAmount;
  return totalInterest;
}

// Calculate the remaining mortgage balance, cumulative interest paid, and cumulative capital paid for each year of the mortgage term
export function calculateYearlyMortgageDetails(mortgageAmount, loanTerm, monthlyRepayment, interestRate) {
  const monthlyInterestRate = interestRate / 1200;

  const yearlyMortgageDetails = [];

  let remainingLoanAmount = mortgageAmount;
  let cumulativeInterestPaid = 0;
  let cumulativeCapitalPaid = 0;

  // Add an entry for year 0
  yearlyMortgageDetails.push({
    year: 0,
    remainingLoanAmount: mortgageAmount,
    cumulativeInterestPaid: 0,
    cumulativeCapitalPaid: 0,
    annualInterestPaid: 0,
    annualCapitalPaid: 0,
    annualTotalPaid: 0,
    cumulativeTotalPaid: 0
  });

  for (let i = 1; i <= loanTerm; i++) {
    let annualInterestPaid = 0;
    let annualCapitalPaid = 0;

    // Calculate interest and principal payments for each month
    for (let j = 0; j < 12; j++) {
      const interestPayment = remainingLoanAmount * monthlyInterestRate;
      let principalPayment = monthlyRepayment - interestPayment;

      // Adjust the final payment if it's more than the remaining loan amount
      if (remainingLoanAmount - principalPayment < 0) {
        principalPayment = remainingLoanAmount;
      }

      remainingLoanAmount -= principalPayment;

      annualInterestPaid += interestPayment;
      annualCapitalPaid += principalPayment;
      cumulativeInterestPaid += interestPayment;
      cumulativeCapitalPaid += principalPayment;

      // Stop payments once the balance hits 0
      if (remainingLoanAmount <= 0) {
        break;
      }
    }

    const annualTotalPaid = annualInterestPaid + annualCapitalPaid;
    const cumulativeTotalPaid = cumulativeInterestPaid + cumulativeCapitalPaid;

    // Add the results to the yearlyMortgageDetails array
    yearlyMortgageDetails.push({
      year: i,
      remainingLoanAmount: remainingLoanAmount,
      cumulativeInterestPaid: cumulativeInterestPaid,
      cumulativeCapitalPaid: cumulativeCapitalPaid,
      annualInterestPaid: annualInterestPaid,
      annualCapitalPaid: annualCapitalPaid,
      annualTotalPaid: annualTotalPaid,
      cumulativeTotalPaid: cumulativeTotalPaid
    });

    // Exit the loop if the remaining balance is 0
    if (remainingLoanAmount <= 0) {
      break;
    }
  }

  return yearlyMortgageDetails;
}

